@import './src/components/common/Input/Input.module.scss';

.locationSearchInput {
  @include Input-base;
  @include Input-responsive-borders;
  @include Input-event-edit-borders;
}

.autocompleteDropdownContainer {
  /**
      * Box shaddow styling cannot live in this container because this element is always in the DOM
      * even with nothing in it. The box shadow will be visible at all times. For that reason
      * the styling live in the children elements.
      *
      * Definitely not the most ideal approach but it works.
      */

  .suggestionItem {
    font-size: 0.9em;
    cursor: pointer;
    background: #ffffff;
    padding: 0.8em 1em;
    box-shadow: 0 0.4rem 0.5rem 0.0625rem #dbdbdc;
    border-bottom: 1px solid #f6f6f6;

    /* Hover is included because the 'plaintext' user input is excluded from being styled automatically
      so we have to manually implement styles */
    &Active,
    &:hover {
      background: #fafafa;
    }

    &:first-of-type {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    &:last-of-type {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}
