@import './src/styles/lib';

.ThemePicBlurryBackdrop {
  // display: none;
  // `lg` is where the event read page changes structure. TODO DRY
  @include lg {
    display: block;
  }

  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  overflow: hidden;

  .inner {
    filter: blur(100px) brightness(0.9);
    transform: scale(1);
  }
}
