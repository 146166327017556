@import './src/styles/lib';

:global {
  .react-calendar {
    border: none !important;
  }

  .react-calendar__navigation {
    height: 18px !important;
    margin-top: 32px !important;
    margin-bottom: 16px !important;
  }

  .react-calendar__navigation__label {
    pointer-events: none;
  }

  .react-calendar__navigation__label__labelText {
    text-transform: uppercase;
    color: $gray-6;
    font-size: $fontSizeSm;
    font-weight: bold;
  }

  .react-calendar__navigation__arrow {
    background-color: transparent !important;
    cursor: inherit;
  }

  .react-calendar__month-view__days__day--weekend {
    color: inherit;
  }

  // Day of the week headers
  .react-calendar__month-view__weekdays__weekday {

    abbr,
    abbr[title] {
      text-decoration: none;
      color: $black;
      text-transform: none !important;
      font-size: $fontSizeSm !important;
      font-weight: normal !important;
    }
  }

  .react-calendar__tile:disabled {
    background-color: transparent !important;
    color: $gray-d;
    pointer-events: none;
  }
}

.highlight {
  color: $red !important;
}
