@import './src/styles/lib';

.react-datepicker__input-container {
  input[type="text"] {
    @include form-text-input;
    width: 100%;
  }
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-popper {
  z-index: 11 !important; // 1 more than a component of the widget below it
}
