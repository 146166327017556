@import './src/styles/lib';

.container {
  padding-bottom: spacing(1);
  margin-bottom: spacing(1);

  &:not(:last-child) {
    @include gray-border-bottom;
  }

  .header {
    display: flex;
    align-items: center;

    .label {
      flex-grow: 1;
    }

    &.noMessage {
      padding-bottom: spacing(2);
    }
  }

  .footer {
    padding-right: spacing(4);
  }

  .message {
    @include font-sm;
    color: $black-fade;
    display: flex;

    .resendLink {
      margin-left: spacing(1);
    }
  }

  .remove {
    margin-top: 1px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-width: spacing(4);

    .removeButton {
      display: flex;
      align-items: center;
      cursor: pointer;
      opacity: 0.5;
      transition: opacity 0.3s;

      &:hover {
        opacity: 1;
      }
    }
  }
}
