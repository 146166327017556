@import './src/styles/lib';

.Receipt {
  background-color: white;
  padding: $semiLargeSpacing;
  border: 1px solid $gray-8;
  .Title {
    font-weight: bold;
  }
}

.DetailList {
  margin-top: $spacing;
}

.Detail {
  margin-top: $smallSpacing;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.DetailTitle {
  font-weight: bold;
}

.DetailValue {
  color: $gray-6;
}

.Return {
  @include font-3xl;
  margin-top: $xlargeSpacing;
}

.lineItemName {
  margin-right: spacing(3);
}


.HostMessage {
  border-left: 2px solid $gray-a;
  padding-left: .5em;
  margin-top: .5em;
}
