@import './src/styles/lib';

.MuiSnackbar-root {
  width: 100% !important;
  padding: 0 4px !important;
  left: 0 !important;
  right: 0 !important;

  @include sm {
    left: 0 !important;
    right: auto !important;
  }
}

.MuiSnackbarContent-message {
  margin: auto !important;
}

.snackbar-success,
.snackbar-error,
.snackbar-info,
.snackbar-warning {
  @include font-base;
  color: #000000 !important;
  width: 90% !important;
  max-width: 747px !important;
  margin: auto !important;
  box-shadow: none !important;
  border-radius: none !important;
}

.snackbar-success {
  background-color: $successMessage !important;
}

.snackbar-error {
  background-color: $errorMessage !important;
}

.snackbar-info {
  background-color: $successMessage !important;
}

.snackbar-warning {
  background-color: $warningMessage !important;
}
