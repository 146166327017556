.ThemeColorWidget {
  width: auto !important;
  :global {
    .react-colorful {
      &__saturation {
        border-radius: 0 !important;
      }
      &__hue {
        border-radius: 0;
      }
      &__alpha {
        border-radius: 0;
      }
    }
  }
}
