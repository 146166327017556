@import './src/styles/lib';

.container {
  @include input-container;
  @include md {
    flex-direction: row;
  }
}

.form {
  display: none;
  padding-top: spacing(3);
  margin-top: spacing(3);
  min-width: 300px;
  max-width: 400px;
  @include gray-border-top;

  @include md {
    flex-direction: row;
    @include gray-border-left;
    padding-left: spacing(4);
    margin-left: spacing(2);
    padding-top: 0;
    margin-top: 0;
  }

  .smallButton {
    @include font-sm;
    opacity: 0.5;
    transition: opacity 0.3s;
    user-select: none;
    @include sm {
      margin-left: spacing(1);
    }
  }

  .section {
    &:not(:last-child) {
      margin-bottom: spacing(3);
    }

    .start,
    .end,
    .timezone {
      display: flex;
      flex-direction: column;
    }

    .timezone {
      margin-top: $tinySpacing;
    }
  }
}

.tzPill {
  &:hover {
    color: $orange;
  }
  &.tzPillOpen {
    background-color: $orange-c;
    color: $orange;
  }
  transition: color 0.3s, background-color 0.3s;
}
