@import '../config/index';

@mixin child-space-y($space) {
  > * {
    margin-bottom: $space;
  }
  > *:last-child {
    margin-bottom: 0px;
  }
}

@mixin child-space-x($space) {
  > * {
    margin-right: $space;
  }
  > *:last-child {
    margin-right: 0px;
  }
}

@mixin flex-row() {
  display: flex;
  flex-direction: row;
}

@mixin flex-column() {
  display: flex;
  flex-direction: column;
}

@mixin column-grid {
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 1fr;
}

@mixin vstack-tiny {
  @include column-grid;
  grid-gap: $tinySpacing;
}
.vstack-tiny {
  @include vstack-tiny;
}

@mixin vstack-small {
  @include column-grid;
  grid-gap: $smallSpacing;
}
.vstack-small {
  @include vstack-small;
}

@mixin row-grid {
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: 1fr;
  align-items: center;
}

@mixin hstack-thin {
  @include row-grid;
  grid-gap: $thinSpacing;
}
.hstack-thin {
  @include hstack-thin;
}

@mixin hstack-tiny {
  @include row-grid;
  grid-gap: $tinySpacing;
}
.hstack-tiny {
  @include hstack-tiny;
}

@mixin hstack-small {
  @include row-grid;
  grid-gap: $smallSpacing;
}
.hstack-small {
  @include hstack-small;
}
