@import './src/styles/lib';

.label {
  @include font-xl;
}

.votes {
  display: inline-block;
  margin-left: spacing(1);
}

.footer {
  @include flex-row;
  margin-top: 32px;
  justify-content: flex-end;
}

.voterName {
  margin: spacing(1) 0 spacing(3) !important;
}

.saveButton {
  min-width: 84px !important;
}
