@import './src/styles/lib';

.button {
  cursor: pointer;
  height: 56px;
  border: 1px solid $gray-d;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  transition: border-color 0.2s linear;
  background-color: white;
  @include font-sm;

  @include sm {
    @include font-base;
  }

  &:focus, &:active {
    border: 1px solid $black;
  }

  &.copied {
    border: 1px solid white;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .text {
    padding-left: spacing(0.5);
    flex-grow: 1;
    @include ellipsis;
  }

  .copyIcon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60px;
  }

  .success {
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    background-color: $successMessage;
    opacity: 0;
    transition: opacity 0.15s linear;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &.copied {
      opacity: 1;
    }
  }
}
