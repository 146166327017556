@import './src/styles/lib';

.PrivacyIndicators {
  @include vstack-tiny;
  color: $gray-8;
  img {
    opacity: .25;
  }
}

.PrivacyIndicatorItem {
  // The first item should have a little bit less spacing then the rest
  // to account for the line-height of the overline label.

  margin-top: $tinySpacing;

  &:not(:first-child) {
    margin-top: $smallSpacing;
  }
}
