@import './src/styles/lib';

.content {
  width: 100vw;

  @include md {
    width: $screen-md-min;
  }

  @include lg {
    width: $screen-lg-min;
  }
}
