@import './src/styles/lib';

.list {
  list-style: decimal outside;
  padding-left: 20px;

  li {
    padding-left: spacing(1);
    line-height: 1.8em;
  }
}
