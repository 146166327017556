@import './src/styles/lib';

.GridRow {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  &.tiny {
    column-gap: $tinySpacing;
  }
  &.small {
    column-gap: $smallSpacing;
  }
  &.medium {
    column-gap: $spacing;
  }
  &.large {
    column-gap: $largeSpacing;
  }
  &.xlarge {
    column-gap: $xlargeSpacing;
  }
}
