@import './src/styles/lib';

.Dropdown {
  height: 100%;

  position: relative;
  user-select: none;

  &:hover {
    cursor: pointer;
  }

  &.align-right > .modal {
    right: 0;
  }
}

.modal {
  position: absolute;
  top: 100%;

  background: $white;

  border-radius: 4px;
  border: 1px solid rgba(34,36,38,.15);
  box-shadow: 0 2px 3px 0 rgba(34,36,38,.15);

  margin-top: 8px;

  z-index: 1000;
}
