@import './src/styles/lib';

.item {
  padding: 12px 0;
  margin-bottom: -1px;
  cursor: pointer;

  color: $gray-8;

  &:hover {
    color: $black;
  }

  &.active {
    border-bottom: 1px solid $black;
    color: $black;
  }
}
