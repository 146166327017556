@import './src/styles/lib';

.title {
    .closeIcon {
      color: $gray-8;
    }
}

.titleText {
    @include font-2xl;
}
