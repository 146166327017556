@import './src/styles/lib';

.input {
  background-color: white;
  border-radius: 0 !important;

  fieldset {
    border-left-width: 0;
    border-right-width: 0;
  }

  .roundedCorners {
    @include sm {
      border-radius: 8px !important;
    }
  }
  @include sm {
    fieldset {
      border-left-width: 1px;
      border-right-width: 1px;
    }
  }
}

.icon {
  margin-right: spacing(1);
}

.addZoom {
  display: flex;
  align-items: center;
  white-space: nowrap;
  position: absolute !important;
  right: 1px;
  top: -30px;
  opacity: 0.75;
  cursor: pointer;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }

  &:active {
    opacity: 0.5;
  }

  &.disabled {
    opacity: 0.75 !important;
    cursor: default;
  }
}

.addIcon,
.createdIcon {
  margin-right: 3px;
  opacity: 0.75;
}

