@import './src/styles/lib';

.Comment {
  opacity: 1;
  transition: opacity 0.2s;

  &.loading {
    opacity: 0.5;
  }

  &.highlight {
    > .body {
      background: $orange-c;
    }
  }

  > .body {
    padding: $tinySpacing $smallSpacing;
    border-radius: 4px;

    &:hover {
      background: $orange-7;
    }
  }

  > .replies {
    padding-left: $spacing;
  }
}

.meta {
  > * {
    @include font-sm;
    margin-right: $tinySpacing;
    color: $gray-8;
  }
}

.text {
  word-break: break-word;
}

.content {
  display: flex;
  align-items: center;

  .left {
    flex: 1;
  }

  .right {
    padding: 0 $tinySpacing;
    margin-left: auto;
  }

  .icon {
    height: 16px;
  }
}

.actions {
  display: flex;

  > * {
    @include font-sm;
    margin-right: $tinySpacing;
    color: $orange;
  }
}
