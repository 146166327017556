@import './src/styles/lib';

.IconWithText {
  display: grid;

  // Make icons equal width so that, when vertically stacked,
  // the text all starts at the same place
  grid-template-columns: 32px 1fr;

  .Icon {
    // margin-left: auto;
    img {
      // We REALLY should be using an icon set with standardized dimensions...
      max-width: 23px;
    }
  }

  .LocationIcon {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 1px;
  }

  .StreamingText {
    padding-left: 2px;
  }

  &.xsmall {
    margin-right: $tinySpacing * 2;
    img {
      width: 14px;
    }
    .Text {
      @include font-sm;
    }
  }

  &.small {
    .Text {
      @include font-base;
    }
  }

  &.medium {
    .Text {
      @include font-base;
      @include sm {
        @include font-lg;
      }
    }
  }

  &AlignTop {
    // The September '19 Event Read Page design requires icons should be top aligned
    // with the text when it wraps and not centered. This class introduced to
    // account for that.
    align-items: start;

    .Icon {
      @include sm {
        // 1.5px was found through trial and error to align this icon set properly
        margin-top: 1.5px;
      }


      // https://stackoverflow.com/questions/32118013/align-icon-vertically-to-the-center-of-the-first-line-of-text
      // this hack allows up to align the icon center with the first line of text
      &::before {
        width: 0px;
        content: 'X';
        visibility: hidden;
      }
    }
  }
}
