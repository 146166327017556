@import './src/styles/lib';

.section {
  border: 1px solid $gray-f;
}

.sectionContent {
  padding: spacing(3);

  @include sm {
    padding: spacing(4);
  }
  @include xl {
    padding: spacing(4);
  }
}

.sectionTitle {
  height: spacing(3.5) !important;
  margin-top: spacing(0.5);
  margin-bottom: spacing(2.5);
}

.titleImage {
  height: 126px !important;

  @include md {
    height: 201px !important;
  }

  @include xl {
    height: 275px !important;
  }
}

.title {
  width: 50%;
  height: spacing(4) !important;
  margin-bottom: spacing(3.5);

  @include md {
    height: spacing(7) !important;
    margin-bottom: spacing(4);
  }
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: spacing(2);

    @include md {
      margin-bottom: spacing(2.5);
    }
  }

  .itemIcon {
    width: spacing(3);
    height: spacing(3) !important;
    margin-right: spacing(2);
  }

  .itemLabel {
    height: spacing(2.5) !important;
  }

  &.small {
    &:not(:last-child) {
      margin-bottom: spacing(1.5);
    }

    .itemIcon {
      width: spacing(2.5);
      height: spacing(2.5) !important;
    }

    .itemLabel {
      height: spacing(2) !important;
    }
  }
}

.commentSection {
  margin-bottom: spacing(5);

  @include md {
    margin-bottom: spacing(5.5);
  }
}

.commentsLabel {
  margin: spacing(0.5) 0;
  height: spacing(2) !important;
  width: 200px;
}

.rsvpButtons {
  display: flex;
  flex-direction: row;

  .rsvpButton {
    flex: 1;
    height: spacing(7) !important;

    &:not(:last-child) {
      margin-right: spacing(1);
    }
  }
}

.guestListControls {
  height: spacing(2) !important;
  margin-bottom: spacing(4);
  margin-top: -(spacing(1));
}
