$smallInputHeight: 44px;
$inputHeight: 56px;

$thinSpacing: 4px;
$tinySpacing: 8px;
$smallSpacing: 16px;
$spacing: 24px;
$semiLargeSpacing: 32px;
$largeSpacing: 48px;
$xlargeSpacing: 64px;

$radius: 8px;
$fieldSpacing: 4px; // vertical space between field and label
$containerPadding: $smallSpacing; // horizontal spacing on Container
$buttonWidth: 192px; // Not required to use this, but if you want a value it's a default

$verticalSpace: $spacing;

@function spacing($multiple) {
  @return $multiple * 8px;
}
