@import './src/styles/lib';

.ButtonContainer {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-gap: $spacing;
  }
  .SubmitButton,
  .CancelButton,
  .DeleteButton {
    width: 100%;
  }

  @include sm {
    .ButtonContainer {
      display: grid;
      grid-template-rows: 1fr; // override mobile style
      grid-template-columns: 1fr auto auto;
    }

    .SubmitButton {
      width: $buttonWidth;
    }

    .CancelButton,
    .DeleteButton {
      justify-self: end;
      width: 128px;
    }
  }
