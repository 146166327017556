.VirtualVenueLandingPage {
  :global {
    .bg-orange {
      background-color: #fffaf7;
    }
    .bg-default {
      background-color: #ef8f71;
    }
    .bg-gray {
      background-color: #706966;
    }
    .bg-gray-100 {
      background-color: #665f5d;
    }
    .bg-black-100 {
      background-color: #5b5553;
    }
    .border-dark {
      border-color: #231f20;
    }
    .border-gray {
      border-color: #a7a9ac;
    }
    .border-gray-100 {
      border-color: #6d6e71;
    }

    .text-black {
      color: #231f20;
    }
    .text-black-100 {
      color: #5b5553;
    }
    .text-default {
      color: #ef8f71;
    }
    .text-gray {
      color: #6d6e71;
    }

    .line-height-normal {
      line-height: normal !important;
    }

    .slick-next,
    .slick-prev {
      z-index: 10;
    }
    .slick-next {
      right: 25px;
    }
    .slick-prev {
      left: 25px;
    }

    .slick-slider .slick-dots {
      bottom: -35px;
    }
    .slick-list .slick-track {
      display: flex;
    }
    .slick-list .slick-slide {
      height: auto;
    }
    .slick-slide > div {
      height: 100%;
    }
    .slick-slider .slick-dots li button:before {
      font-size: 15px;
      color: #857e7d;
    }
    .slick-slider .slick-dots li.slick-active button:before {
      color: #857e7d;
    }
    .slick-prev:before,
    .slick-next:before {
      color: #ccc;
    }
  }
}
