@import './src/styles/lib';

.SubmitButtonWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: $black-fade;

  > *:first-child {
    flex-grow: 1;
  }

  > *:nth-child(2) {
    margin-left: 1px;
  }
}
