@import './src/styles/lib';

.resizeHandle {
  position: absolute;
  background: white;
  border: 1px solid $gray-8;
  transform: translateX(-50%) translateY(-50%);
  width: 12px;
  height: 12px;
}
