@import './src/styles/lib';

.Icon {
  height: auto;

  // Icons can be inside fixed-width IconWithText columns
  // Use text-align: right to line them up at equal distance from the text.
  // The gaps on the left will vary in size, but that's a lesser evil than
  // varying the gaps between the icon and its attached text.
  text-align: right;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  > img {
    max-width: 100%;
  }

  // Sizes
  &.size-small {
    width: 16px;
  }

  &.size-medium {
    width: 24px;
  }

  &.size-large {
    width: 32px;
  }
}
