.ActionButton {
  padding: 5px;

  &:not(.disabled) {
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.25s linear;

    &:hover {
      opacity: 1;
    }
  }

  &.disabled {
    opacity: 0.25;
  }
}
