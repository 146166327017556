@import './src/styles/lib';

.RootLayout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  transition: background-color $themeColorTransitionDelay;
  background-color: $backgroundColor;
}
