@import './src/styles/lib';

.Confirm {}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  @include child-space-x(10px);
}

.text {
  line-height: 1.25;
  @include font-lg;
}
