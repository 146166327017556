@import './src/styles/lib';

.TimeTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 38px; // This is the height when collapser is present; use min-height to equalize
  .label {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 500;
    color: $gray-8;
    letter-spacing: 0.02em;
    line-height: normal;
  }
}
