@import './src/styles/lib';

.AuthLoginFormButton {
  width: 100%;
  margin-top: $tinySpacing;
}

.AuthFooter {
  display: flex;
  flex-direction: column;
  text-align: center;

  @include font-lg;
  margin-top: 30px;

  span {
    margin-top: 8px;
  }
}
