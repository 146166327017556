@import './src/styles/lib';

.Error {
  padding: 20px;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;

  &.root {
    height: 100vh;

    .message {
      font-size: 24px;
    }
  }
}

.message {
  font-size: 16px;
}
