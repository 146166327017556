.GridContainer {
  display: grid;
  position: relative;
  grid-gap: 4px;
  grid-template-rows: 1fr auto;

  justify-content: center;
  & > div {
    grid-row: 2;
    &.Speaker {
      grid-row: 1;
      margin: auto;
    }
  }
}
