.GridContainer {
  display: grid;
  position: relative;
  grid-gap: 4px;
  justify-content: center;
  & > div {
    &.Spotlight {
      margin: auto;
    }
  }
}
