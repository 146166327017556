@import './src/styles/lib';

.Checkbox {
  display: inline-flex;
  align-items: center;

  cursor: pointer;
}

.label {
  margin-left: 8px;
  line-height: 1.5;
}

.Options {
  display: flex;
  @include child-space-y(10px);
}
