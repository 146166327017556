:global {
  .ChatNameForm {
    label > .field-label {
      color: var(--v-theme-chat-text-color)
    }

    // This should be same as the style used for names in the chat content
    .field-help-text {
      color: var(--v-theme-chat-text-color);
      filter: brightness(0.9);
      opacity: .6;
    }

    input::placeholder {
      color: var(--v-theme-chat-text-color);
      filter: brightness(0.9);
    }
  }
}
