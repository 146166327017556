@import './src/styles/lib';

.TicketInfoDialog {
  padding-bottom: $spacing !important;
  .box {
    background-color: $gray-f;
    padding: $tinySpacing;
    border: 1px solid $gray-6;
  }
  .button {
    margin-top: $spacing;
  }
}
