.ChatMessage {
  .ShowOnHover {
    display: none;
  }
  &:hover {
    .ShowOnHover {
      display: block;
    }
  }

  a, a:hover {
    color: inherit !important;
  }
}
