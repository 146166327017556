@import './src/styles/lib';

.container {
  @include input-container;
}

.actionButtons {
  position: absolute;
  top: $thinSpacing;
  right: $thinSpacing;
  @include hstack-thin;
}

.DiscountCodeWidget {
  @include vstack-small;
  row-gap: spacing(2);

  .codeField,
  .typeField,
  .amountField,
  .activeField {
    grid-column: span 3;
  }

  @include sm {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: spacing(2);
    align-items: center;

    .codeField,
    .typeField,
    .amountField,
    .activeField {
      margin-bottom: 0;
      grid-column: span 1;
    }
  }

  @include md {
    column-gap: spacing(3);
  }

  .disabledNote {
    grid-column: span 2;
    @include font-base;
    font-style: italic;
    opacity: 0.35;
  }
}
