@import './src/styles/lib';

.tooltip {
  font-size: 12px;

  > div:nth-child(2) {
    margin-top: 5px;
  }
}

.votes {
  color: $gray-8;
  font-size: 14px;
  cursor: default;
  user-select: none;
}
