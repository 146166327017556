@import './src/styles/lib';

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  margin-bottom: 24px;
}

.nav {
  display: flex;
  flex-direction: row;
  @include child-space-x(40px);
}
