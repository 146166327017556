@import './src/styles/lib';

// Using non-Tailwind styles so that Button2 users can easily override styles without worrying about CSS class specificity

// Compiled via tailwind
/* Todo: set up PostCSS so that we can use tailwind here */
// .btn {
//   @apply shadow-sm;
//   @apply border;
//   @apply border-transparent;
//   @apply outline-none;
//   @apply w-full;
//   @apply items-center;
//   @apply inline-flex;
//   @apply justify-center;
//   @apply align-center;
//   @apply font-semibold;
//   @apply leading-none;
//   @apply whitespace-nowrap;
//   @apply transition;
//   @apply ease-in-out;
//   @apply duration-150;
//   "@apply sm:w-auto;"
// }
// .btn:focus {
//   @apply outline-none;
// }

.Button2 {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border-width: 1px;
  border-color: transparent;
  outline: 0;
  width: 100%;
  -webkit-box-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  font-weight: 500;
  line-height: 1;
  white-space: nowrap;
  -webkit-transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow,
    -webkit-transform;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-transform;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform,
    -webkit-transform;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  transition-duration: 150ms;

  &:focus {
    outline: 0;
  }
  @include sm {
    width: auto;
  }
}

.secondary {
  border-color: $gray-900;
  background-color: white;
  color: $gray-900;
  &:hover {
    color: $black;
  }
}
