@import './src/styles/lib';

.emailPreview {
  pointer-events: none;
}

.subject {
  padding: spacing(2) spacing(3);
  @include gray-border-bottom();
}

.subjectLabel {
  font-weight: 600;
}

.previewBody {
  > div {
    padding: spacing(3) !important;
  }
  img {
    max-width: 100%;
  }
  strong {
    font-weight: 600;
  }
  h1 {
    line-height: 1.2em;
  }
}

.dialogContent {
  padding: 0 !important;
}
