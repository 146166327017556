@import './src/styles/lib';

// Override react-time-picker styles
.TimePickerWidget {
  width: fit-content;
  .react-time-picker, .react-time-picker .react-time-picker__wrapper {
    width: 100%;
  }

  .react-time-picker__wrapper {
    border: 1px solid rgba(34,36,38,.15) !important;
    // border-radius: .2857rem !important;
    background: white !important;
    color: #3e3d45;
    line-height: 1.5;
    padding: .5rem 1rem !important;

    input, select {
      height: auto !important;
      border: none !important;
      background: transparent !important;
      margin: 0 !important;
      padding: 0 !important;
    }

    .react-time-picker__inputGroup__hour {
      // Enough width that it doesn't grow when you go to 2-digit hours
      min-width: 18px;
      text-align: right;
    }

    .react-time-picker__inputGroup__amPm {
      margin-left: .5em !important;
      background: transparent !important;
      -webkit-appearance: none !important;
      width: fit-content !important;
    }
  }
}
