.BackgroundBlur {
  overflow: hidden;

  // Firefox backdrop, uses another html element as it's background
  &::before {
    background: -moz-element(#virtual-venue-container) center center;
    content: ' ';
    margin: -35px;
    position: absolute;
    opacity: .95;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    filter: blur(14px);
    backdrop-filter: blur(14px);
    z-index: -1;
  }

  &::after {
    background: var(--v-theme-bg-color);
    content: ' ';
    margin: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: .8;
    z-index: 0;
  }
}
