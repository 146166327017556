@import './src/styles/lib';

.Container {
  z-index: 10;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-left: $containerPadding;
  padding-right: $containerPadding;

  @include sm {
    max-width: $screen-sm-min;
  }
  @include md {
    max-width: $screen-md-min;
  }
  @include lg {
    max-width: $screen-lg-min;
  }
  @include xl {
    max-width: $screen-xl-min;
  }

  &.wide {
    max-width: unset;
    padding-left: $containerPadding * 2;
    padding-right: $containerPadding * 2;
  }

  &.nopadding {
    padding: 0;
    @include sm {
      padding-left: $containerPadding;
      padding-right: $containerPadding;
    }
  }
}
