@import '../config/index';

@mixin font-xs {
  font-size: $fontSizeXs;
  line-height: $fontHeightXs;
}

@mixin font-sm {
  font-size: $fontSizeSm;
  line-height: $fontHeightSm;
}

@mixin font-base {
  font-size: $fontSizeBase;
  line-height: $fontHeightBase;
}

@mixin font-lg {
  font-size: $fontSizeLg;
  line-height: $fontHeightLg;
}

@mixin font-xl {
  font-size: $fontSizeXl;
  line-height: $fontHeightXl;
}

@mixin font-2xl {
  font-size: $fontSize2xl;
  line-height: $fontHeight2xl;
}

@mixin font-3xl {
  font-size: $fontSize3xl;
  line-height: $fontHeight3xl;
}

@mixin font-4xl {
  font-size: $fontSize4xl;
  line-height: $fontHeight4xl;
}

@mixin font-5xl {
  font-size: $fontSize5xl;
  line-height: $fontHeight5xl;
}

@mixin font-6xl {
  font-size: $fontSize6xl;
  line-height: $fontHeight6xl;
}
