.AddButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 0.5;
  transition: opacity 0.25s linear;
  cursor: pointer;
  margin-left: spacing(2);

  &:hover {
    opacity: 1;
  }
}
