@import './src/styles/lib';

.OutgoingMessagesModal {
  border-radius: 0 !important;
  padding-top: 32px !important;
}
.Toolbar {
  margin-top: $spacing;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include sm {
    flex-direction: row;
  }
}
.DeleteButton {
  margin-top: $spacing;
  @include sm {
    margin-top: 0;
  }
}
.OutgoingMessagesHeader {
  border: none !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  > h1, > h2 {
    text-align: left;
    font-weight: normal;
    color: $black;
  }
  > h1 {
    @include font-3xl;
    margin-top: 0 !important;
  }
  > h2 {
    @include font-base;
  }
}

