@import "./reset.css";
@import "./tailwind/generated/tailwind.generated.css";
@import "../assets/fonts/Inter-web-unhinted/inter.css";
@import "../assets/fonts/Bariol/bariol.css";
@import "./Snackbar.scss";
@import './src/styles/lib';

//
// Base elements
//

html,
body {
  margin: 0;
  padding: 0;
  min-height: 100%;
}

body {
  @include font-base;
  color: $textColor;
  font-family: $fontStack;
  overflow-x: hidden;
  word-wrap: break-word;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: -0.01em;
}

// see trackFocusMouse
body.focus--mouse {
  * {
    outline: 0;
  }
}

*,
*:after,
*:before {
  box-sizing: border-box;
  outline: none;
}


a {
  color: $linkColor;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

input,
textarea {
  font-size: 16px;
  font-family: 'Inter var', sans-serif;
  line-height: 18px;
}

b,
strong {
  font-weight: 600;
}

i,
em {
  font-style: italic;
}


// Override MUI crap
.MuiFormControlLabel-root {
  margin-right: 0 !important;
}


//
// One-off styles, to be used when an element doesn't need any other styling.
//

.label--overline {
  color: $gray-8;
  @include font-xs;
  font-weight: 500;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.filter-black--with-transition {
  filter: brightness(0) grayscale(1);
  transition: filter .2s;
}

.filter-white--with-transition {
  filter: brightness(0) invert(1);
  transition: filter .2s;
}

hr.thin-divider {
  border: none;
  margin: 0;
  border-top: 1px solid #eaeaea;
}

.only-gte-sm {
  display: none;

  @include sm {
    display: initial;
  }
}

.transition-bgcolor-theme {
  transition: background-color $themeColorTransitionDelay;
}

.h2-responsive {
  @include h2-responsive;
}

.h3-responsive {
  @include h3-responsive;
}

.h4-responsive {
  @include h4-responsive;
}

.no-child-link-underline {
  a {
    text-decoration: none !important;
  }
}

.horizontal-flip {
    transform: scale(-1, 1);
}

button:focus {outline:0;}
