.TrayButton {
  color: var(--v-theme-icon-color);

  .ButtonLabel {
    position: absolute;
    font-size: 12px;
    top: -7px;
    left: 50%;
    transform: translateX(-50%);
    background: var(--v-theme-bg-color);
    padding-left: 3px;
    padding-right: 3px;
    line-height: 1em;
    font-weight: 500;
    border-radius: 4px;
  }
  &:hover, &.open {
    .Icon {
      // opacity: 1;
      filter: drop-shadow( 0px 0px 1px rgba(255, 255, 255, .5));
    }
  }
}
