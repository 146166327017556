@import './src/styles/lib';

.RsvpForm {
  &Header {
    padding: $spacing $spacing 0;
    @include sm {
      padding: $semiLargeSpacing $semiLargeSpacing 0;
    }

    &Heading {
      @include event-read-page-responsive-h4-heading;
    }
  }

  &StateWidgetWrapper {
    padding: 0 $spacing $semiLargeSpacing;
    @include sm {
      padding: 0 $semiLargeSpacing $semiLargeSpacing;
    }
  }

  &InnerWrapper {
    padding: $spacing $semiLargeSpacing $semiLargeSpacing;
  }

  &LogoutLinkWrapper {
    margin-top: -(spacing(3));
    text-align: center;
    padding: 0 $spacing $spacing;

    &.blocked {
      margin-top: -(spacing(1));
    }
  }
}
