@import './src/styles/lib';

.OrderSummary {
  margin-top: spacing(4);

  hr {
    border-bottom: 0;
    border-top: 1px solid $gray-d;
  }
}

.label {
  @include label-overline;
  margin-bottom: spacing(1);
}

.none {
  font-style: italic;
  color: $black-fade;
}

.lineItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
