$fontSizeXs: 12px;
$fontHeightXs: 16px;

$fontSizeSm: 14px;
$fontHeightSm: 20px;

$fontSizeBase: 16px;
$fontHeightBase: 24px;

$fontSizeLg: 18px;
$fontHeightLg: 28px;

$fontSizeXl: 20px;
$fontHeightXl: 30px;

$fontSize2xl: 24px;
$fontHeight2xl: 32px;

$fontSize3xl: 28px;
$fontHeight3xl: 36px;

$fontSize4xl: 40px;
$fontHeight4xl: 56px;

$fontSize5xl: 48px;
$fontHeight5xl: 60px;

$fontSize6xl: 64px;
$fontHeight6xl: 76px;
