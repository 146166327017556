@import './src/styles/lib';

.NoEventsFooter {
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .footerLabel {
    @include font-lg;
    color: $gray-8;

    margin-bottom: $spacing;
  }
}

.createButton,
.createButton:active,
.createButton:visited,
.createButton:hover {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none !important;
  transition: background-color 0.2s;
  background-color: $black;
  color: $white;
  padding: 5px $smallSpacing 5px $tinySpacing;
  border-radius: 3px;

  .createIcon {
    margin-right: 5px;
    color: $white;
  }

  &:hover {
    background-color: black;
  }
}

.shortLabel {
  display: inline;

  @include sm {
    display: none;
  }
}

.longLabel {
  display: none;
  @include sm {
    display: inline;
  }
}
