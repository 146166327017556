@import './src/styles/lib';

.Description {
  .heading {
   @include event-read-page-responsive-h4-heading
  }

  .body {
    img {
      margin: 12px auto;
    }
    margin-top: $smallSpacing;
    @include font-base;
    line-height: 20px;

    @include md {
      @include font-lg;
    }

    strong {
      font-weight: 600;
    }

    em, i {
      font-style: italic;
    }
  }
}
