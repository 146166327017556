@import './src/styles/lib';

.EventLayout {
  background-color: $backgroundColor;
}

.container {
  position: relative;
  z-index: 5;
}
