@import './src/styles/lib';

.SummaryOverline {
  color: $gray-6;
  display: block;

  &:hover {
    text-decoration: none;
  }
}
