.CallTile {
  &.ShowNameOnHover {
    .Name {
      opacity: 0;
      transition: opacity 0.25s;
    }
    &:hover {
      .Name {
        opacity: 1;
      }
    }
  }
  .UnpinContainer {
    .PinnedIcon {
      display: flex;
    }
    .UnpinIcon {
      display: none;
    }
    &:hover {
      .PinnedIcon {
        display: none;
      }
      .UnpinIcon {
        display: flex;
      }
    }
  }
}
