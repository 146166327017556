// https://www.digitalocean.com/community/tutorials/css-scrollbars
// https://css-tricks.com/the-current-state-of-styling-scrollbars/

.VirtualVenueDisplayContainer {
  border: 1px solid var(--v-theme-grid-line-color);

  * {
      /*change the thinkness of the scrollbar here*/
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      -webkit-box-shadow: inset 0 0 8px rgba(0, 0, 0, 0);
      -webkit-border-radius: 8px;
      border-radius: 8px;
      background-color: transparent;
    }
    /*add a shadow to the scrollbar here*/
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
      -webkit-border-radius: 8px;
      border-radius: 8px;
    }
    /*this is the little scrolly dealio in the bar*/
    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: var(--v-theme-scrollbar-color);
      height: 3px;
    }
    /*nobody needs this little scrollbar corner, I mean really, get rid of it haha*/
    &::-webkit-scrollbar-corner {
      display: none;
      height: 0px;
      width: 0px;
    }

    scrollbar-width: thin;
    scrollbar-color: var(--v-theme-scrollbar-color) transparent;
  }
}
