
// Phones
$screen-xs-min: 480px;

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 600px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Modals in small desktops (modals go full screen)
$screen-mdek-min-width: 960px;
$screen-mdek-min-height: 590px;

// Tablets and small desktops
$screen-lg-min: 1024px;

// Modals in tablets and small desktops
$screen-mlg-min: 1075px;

// Large tablets and desktops
$screen-xl-min: 1224px;

