@import './src/styles/lib';

.container {
  @include input-container;
  @include md {
    flex-direction: row;
  }
}

.content {
  padding-top: spacing(3);
  margin-top: spacing(3);
  flex-grow: 1;
  @include gray-border-top;

  @include md {
    flex-direction: row;
    @include gray-border-left;
    padding-left: spacing(4);
    margin-left: spacing(3);
    padding-top: 0;
    margin-top: 0;
  }
}


.changeNote {
  @include font-base;
  font-style: italic;
  opacity: 0.35;
  margin: spacing(1) 0 0;

  @include md {
    max-width: 180px;
  }
}

.typeRadio {
  // Make sure the text doesn't wrap
  min-width: 190px;
}
