@import './src/styles/lib';

.CommentInput {
  position: relative;

  .textarea {
    min-height: 52px;
    border-radius: 4px;
    padding: $smallSpacing;
    padding-right: 96px;
  }

  .button {
    position: absolute;
    top: 8px;
    right: 8px;
    @include lg {
      top: 4px;
      right: 4px;
    }

    border-radius: 4px;

    opacity: 0;

    &.visible {
      opacity: 1;
    }
  }
}
