@import './src/styles/lib';

.EventThemePicResizer {
  position: relative;

  &.can-resize {
    cursor: ns-resize;

    &.can-move-x {
      cursor: move;
    }

    .ui {
      opacity: 1;
    }
  }
}

.center, .centerBottom, .centerRight {
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  &.center {
    top: 0;
  }
}

.center {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.centerBottom {
  left: 0;
  right: 0;
  bottom: 0;
}

.centerRight {
  top: 0;
  right: 0;
  bottom: 0;
}

.ui {
  @include font-sm;
  font-weight: 500;

  color: $white;
  background: rgba(0, 0, 0, 0.7);

  padding: $fieldSpacing $spacing;
  border-radius: 4px;

  transition: opacity 0.6s;
  opacity: 0;
}

.Zoom {
  display: flex;
  margin-bottom: 16px;

  padding: $fieldSpacing;
}

.zoomButton {
  cursor: pointer;
  user-select: none;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 32px;
  height: 24px;
  color: $white;

  &.disabled {
    color: $gray-6;
  }
}
