@import './src/styles/lib';

$fader-height: 40px;

.GuestList {
  max-height: 240px;
  overflow-y: auto;
  padding-bottom: 32px; //$fader-height;
  margin-left: -(spacing(1));
}

.Fader {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: $fader-height;
  background: linear-gradient(180deg, rgba(255,255,251,0) 0%, rgba(255,255,254,0.4) 22%, rgba(255,255,255,1) 100%);
  pointer-events: none;
}
