@import './src/styles/lib';

.TextLink {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  @include child-space-x(4px);

  color: $linkColor;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
