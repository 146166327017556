@import './src/styles/lib';

.Headline {
  font-weight: 200;
  font-size: 28px;
  line-height: 1.4em;

  @include md {
    font-size: 36px;
  }

  @include lg {
    font-size: 46px;
  }
}

.Subhead {
  font-size: 24px;
  line-height: 1.4em;

  @include md {
    font-size: 16px;
  }

  @include lg {
    font-size: 20px;
    max-width: 380px;
    padding-bottom: 90px;

  }
}

.IntroSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  @include lg {
    text-align: left;
    flex-direction: row;
  }
}

.IntroCopy {
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  @include md {
    max-width: 480px;
  }

  @include lg {
    padding: 0;
    margin-top: 0;
    margin-left: 64px;
    max-width: 406px;
  }
}

.Reverse {
  @include lg {
    flex-direction: row-reverse;
  }
}

.IntroGatherCopy {
  max-width: 480px;

  @include lg {
    text-align: right;
    max-width: 344px;
  };
}

.IntroGatherImage {
  margin-top: 12px;
  @include lg {
    margin-top: 0;
    margin-left: 64px;
  }
}
