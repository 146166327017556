@import './src/styles/lib';

.GuestListCounts {
  display: flex;
  justify-content: space-between;

  // Matches privacy indicators
  color: $gray-8;

  list-style: none;
  margin: 0px;
  padding: 0px;
}

.GuestListDetailSection {
  padding: $spacing $spacing 0;
  @include sm {
    padding: $semiLargeSpacing $semiLargeSpacing 0;
  }
}

.EventTicketListView {
  padding: $spacing;
  @include sm {
    padding: $semiLargeSpacing;
  }}

.GuestListActionSection {
  padding: 0 $spacing $spacing;
  @include sm {
    padding: 0 $semiLargeSpacing $semiLargeSpacing;
  }

}

.GuestListHeading {
  @include event-read-page-responsive-h4-heading;
}

.TicketListOverline {
  color: $gray-6;
}

.NoResults {
  padding: $spacing;
  @include sm {
    padding: $semiLargeSpacing;
  }
  color: $gray-6;
}
