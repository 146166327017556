@import './src/styles/lib';
@import './src/components/pages/EventReadPage/EventThemePic.module.scss';

.ThemePicWidget {
  display: block;
}

.ThemePicPlaceholder {
  background-color: $gray-e;
  position: relative;
  margin-bottom: spacing(1);

  &.dragging {
    background: $orange-c;
  }

  .Spacer {
    height: 180px;
  }

  .Text {
    position: absolute;
    bottom: 0;
    margin: 0 0 16px 16px;
    color: $orange;
  }
}

.uploading {
  background: rgba(255, 255, 255, 0.5);
  z-index: 2;

  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  > .center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .progressText {
    margin-top: $smallSpacing;
    font-weight: bold;
  }
}

.links {
  display: flex;
  margin-left: $smallSpacing;

  @include sm {
    margin-left: 0;
  }

  > * {
    padding-right: $tinySpacing;
  }
}
