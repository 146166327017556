@import './src/styles/lib';

.DiscountCodeForm {
  display: flex;
  flex-direction: column;
  align-items: center;

  &.show {
    align-items: flex-start;
    margin-top: spacing(3);
  }

  .label {
    @include label-overline;
    font-weight: 500 !important;
  }

  .input {
    margin-top: 3px;

    :global {
      .MuiOutlinedInput-adornedEnd {
        padding-right: spacing(1);
      }

      .MuiFormHelperText-contained {
        @include font-xs;
        margin-left: 0;
        text-align: center;
        margin-top: 4px;
      }
    }
  }
}
