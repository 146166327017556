@import './src/styles/lib';

.Pill {
  @include font-sm;
  display: inline-flex;
  align-items: center;
  padding: 2px 14px;
  border-radius: 12px;
  z-index: 1;
  background-color: $gray-f;
  color: $gray-8;
}
