@import './src/styles/lib';

@mixin Input-basics {
  background-color: $white;
  border: 1px solid $gray-d;
  border-radius: 0;
  transition: border-color 0.2s ease;
  &:focus {
    border-color: $black;
    outline: none;
  }
}

@mixin Input-sizing {
  height: $inputHeight;
  &.small {
    height: $smallInputHeight;
  }
  &.responsive {
    height: $smallInputHeight;
    @include sm {
      height: $inputHeight;
    }
  }
}

@mixin Input-base {
  @include Input-basics;
  @include Input-sizing;
  padding: 0.5em 0.75em;
  width: 100%;
}


@mixin Input-event-edit-borders {
  border-radius: 0;
  @include sm {
    border-radius: 8px;
  }
}

@mixin Input-responsive-borders {
  border-left: 0;
  border-right: 0;
  @include sm {
    border: 1px solid $gray-d;
  }
}

.Input {
  @include Input-base;
}
