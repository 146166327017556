@import './src/styles/lib';

.container {
  @include input-container;
  @include md {
    flex-direction: row;
  }
}

.form {
  display: none;
  padding-top: spacing(3);
  margin-top: spacing(3);
  min-width: 300px;
  @include gray-border-top;

  @include md {
    flex-direction: row;
    @include gray-border-left;
    width: 80%;
    padding-left: spacing(4);
    margin-left: spacing(2);
    padding-top: 0;
    margin-top: 0;
  }

  .section {
    &:not(:last-child) {
      margin-bottom: spacing(3);
    }
  }
}
