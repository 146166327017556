@import './src/styles/lib';

.TitleWidget {
  padding: 0;
  border: none !important;
  background-color: transparent;
  min-height: 1em;
  max-width: 100%;
  resize: none;

  margin-left: $smallSpacing;

  @include sm {
    margin-left: 0;
  }

  @include font-4xl;
  @include md {
    @include font-6xl;
  }

  &:focus {
    outline: none;
    &.limitReached {
      color: $gray-8;
    }
  }
}
