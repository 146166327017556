.Loading {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
}

.Spinner {
  width: 32px;
  height: 32px;

  border: 3px solid rgba(0, 0, 0, 0.1);
  border-top: 3px solid #767676;
  border-radius: 50%;

  animation: spin 0.6s linear infinite;

  // Sizes
  &.size-tiny {
    width: 16px;
    height: 16px;
    border-width: 2px;
  }

  &.size-small {
    width: 24px;
    height: 24px;
  }

  &.size-medium {
    width: 32px;
    height: 32px;
  }
}

:global {
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
