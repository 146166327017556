@import './src/styles/lib';

.connected {
  display: flex;
  align-items: center;
}

.success {
  background-color: $successMessage;
  padding: spacing(2) spacing(3);
  display: inline-flex;
  align-items: center;
  margin-right: spacing(1);
  flex-grow: 1;

  .showSm {
    display: inline;
  }
  .hideSm {
    display: none;
  }

  @include md {
    flex-grow: 0;

    .showSm {
      display: none;
    }
    .hideSm {
      display: inline;
    }
  }
}

.checkIcon {
  margin-right: spacing(1);
}

.pricingNote {
  max-width: 680px;
  @include font-sm;
  color: $black-fade;
  margin-top: $spacing;
}
