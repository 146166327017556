.GridContainer {
  display: grid;
  grid-gap: 4px;
  grid-template-columns: repeat(24, 1fr);
  justify-content: center;
  & > div {
    grid-column: span 6;
  }
  &.Has2Tiles, &.Has5Tiles, &.Has6Tiles, &.Has10Tiles, &.Has11Tiles, &.Has12Tiles {
    max-width: 100%;
  }
  &.Has2Tiles, &.Has3Tiles, &.Has4Tiles {
    & > div {
      grid-column: span 12;
    }
  }
  &.Has5Tiles, &.Has6Tiles, &.Has7Tiles, &.Has8Tiles, &.Has9Tiles {
    & > div {
      grid-column: span 8;
    }
  }
  &.Has1Tiles {
    & > div {
      grid-column: 1 / span 24;
    }
  }
  &.Has3Tiles {
    & > :nth-last-child(1) {
      grid-column: 7 / span 12;
    }
  }
  &.Has5Tiles, &.Has8Tiles{
    & > div {
      &:nth-last-child(2) {
        grid-column: 5 / span 8;
      }
      &:nth-last-child(1) {
        grid-column: 13 / span 8;
      }
    }
  }
  &.Has7Tiles {
    & > :nth-last-child(1) {
      grid-column: 9 / span 8;
    }
  }
  &.Has10Tiles, &.Has14Tiles{
    & > div {
      &:nth-last-child(2) {
        grid-column: 7 / span 6;
      }
      &:nth-last-child(1) {
        grid-column: 13 / span 6;
      }
    }
  }
  &.Has11Tiles, &.Has15Tiles{
    & > div {
      &:nth-last-child(3) {
        grid-column: 4 / span 6;
      }
      &:nth-last-child(2) {
        grid-column: 10 / span 6;
      }
      &:nth-last-child(1) {
        grid-column: 16 / span 6;
      }
    }
  }
  &.Has13Tiles {
    & > :nth-last-child(1) {
      grid-column: 10 / span 6;
    }
  }
}
