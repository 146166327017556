@import './src/styles/lib';

.InviteResponse {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 spacing(1) !important;
  min-height: spacing(3.5);
  border-radius: 3px;

  .guestName {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .icon {
    margin-right: spacing(1);

    img {
      max-width: unset;
    }
  }

  .commentIcon {
    margin-left: spacing(1);
    opacity: 0.5;
    margin-top: 1px;
  }

  .actions {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    display: none;

    .actionIcon {
      color: $black;
      opacity: 0.5;
      transition: opacity 0.3s;
      font-size: 20px;
      margin-left: 5px;

      &:hover {
        opacity: 1;
      }
    }
  }

  &.admin {
    cursor: pointer;
    &:hover {
      background-color: $gray-f;
      cursor: pointer;

      .actions {
        display: flex;
      }
    }
  }
}

.tooltip {
  > div:not(:last-child) {
    margin-bottom: spacing(0.5);
  }
}
