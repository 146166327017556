@import './src/styles/lib';

.QuillRichTextWidget {
  background: $white;
  position: relative;

  :global {
    .ql-container {
      border-top: 1px solid $gray-d;
      border-bottom: 1px solid $gray-d;
      border-radius: 0;

      @include sm {
        border: 1px solid $gray-d;
        border-radius: 8px;
      }
    }

    .ql-editor {
      font-size: 16px;
      line-height: 1.5;
      font-family: $fontStack;

      min-height: 144px;
      padding: 12px;

      overflow-y: visible;

      &.ql-blank:before {
        font-style: normal;
        color: $gray-8;
      }

      img {
        display: block;
        max-width: 100%;
        min-width: 16px;
      }
    }

    .ql-bubble .ql-tooltip {
      border-radius: 4px;
      background: $black;
      background-image: linear-gradient(180deg, rgba(48, 48, 48, 0.95), #303030);

      > .ql-tooltip-arrow {
        border-bottom-color: #303030;
      }

      .ql-formats {
        margin-top: 4px;
        margin-bottom: 4px;
      }
    }

    .ql-container {
      &.hide-toolbar .ql-tooltip {
        opacity: 0;
      }
    }

    .ql-container.ql-bubble:not(.ql-disabled) a {
      // NOTE(nick): link preview popup
      &::before {
        border-radius: 4px;
        background: $black;
        background-image: linear-gradient(180deg, rgba(48, 48, 48, 0.95), #303030);
      }

      &::after {
        border-top-color: rgba(48, 48, 48, 1);
      }
    }
  }
}
