@font-face {
  font-family: 'Bariol';
  font-style:  normal;
  font-weight: 100;
  src: url("./Bariol_Thin/Bariol_Thin.otf") format("opentype");
}
@font-face {
  font-family: 'Bariol';
  font-style:  italic;
  font-weight: 100;
  src: url("./Bariol_Thin/Bariol_Thin_Italic.otf") format("opentype");
}

@font-face {
  font-family: 'Bariol';
  font-style:  normal;
  font-weight: 300;
  src: url("./Bariol_Light/Bariol_Light.otf") format("opentype");
}
@font-face {
  font-family: 'Bariol';
  font-style:  italic;
  font-weight: 300;
  src: url("./Bariol_Light/Bariol_Light_Italic.otf") format("opentype");
}

@font-face {
  font-family: 'Bariol';
  font-style:  normal;
  font-weight: 400;
  src: url("./Bariol_Regular/Bariol_Regular.otf") format("opentype");
}
@font-face {
  font-family: 'Bariol';
  font-style:  italic;
  font-weight: 400;
  src: url("./Bariol_Regular/Bariol_Regular_Italic.otf") format("opentype");
}

@font-face {
  font-family: 'Bariol';
  font-style:  normal;
  font-weight: 700;
  src: url("./Bariol_Bold/Bariol_Bold.otf") format("opentype");
}
@font-face {
  font-family: 'Bariol';
  font-style:  italic;
  font-weight: 700;
  src: url("./Bariol_Bold/Bariol_Bold_Italic.otf") format("opentype");
}
