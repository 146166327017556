@import './src/styles/lib';

.ticket {
  &.fade {
    opacity: 0.5;
  }

  &:not(:last-child) {
    margin-bottom: spacing(2);
    padding-bottom: spacing(2);
    border-bottom: 1px solid #ccc;
  }
}

.summary {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding-bottom: spacing(0.5);
}

.header {
  flex-grow: 1;
}

.name {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.nameInner {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.description {
  @include font-sm;
  opacity: 0.5;

  &.short {
    display: block;
    display: -webkit-box;
    max-width: 100%;
    max-height: 41px;
    margin: 0 auto;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }
}

.form {
  margin-bottom: spacing(2);
}

.icons {
  margin-left: spacing(1);
  display: flex;
}

.soldOut {
  @include font-xs;
  background-color: #F9CACA;
  color: #D32F2F;
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid #E57373;
}

// .lowStock {
//   @extend .soldOut;
//   background-color: #FFE0B2;
//   color: #EF6C00;
//   border: 1px solid #FFB74D;
// }

// .sufficientStock {
//   @extend .soldOut;
//   background-color: #C8E6C9;
//   color: #2E7D32;
//   border: 1px solid #81C784;
// }

.withStock {
  @extend .soldOut;
  background-color: #E0E0E0;
  color: #000000;
  border: 1px solid #B0B0B0;
}

.price {
  white-space: nowrap;
}
