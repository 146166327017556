@import './src/styles/lib';

.PollEdit {
  min-width: 300px;

  .addButton {
    margin-top: spacing(2);

    .label {
      @include font-base;
      text-transform: none;
    }
  }
}
