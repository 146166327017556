@import './src/styles/lib';

.SelectInviteesWidget {
  color: transparentize($black, 0.2);

  .mx-custom-option {
    display: flex;
    align-items: center;
    padding-left: spacing(1.5);
    padding-right: spacing(1.5);

    &.mx-focused {
      background-color: $gray-f;
    }

    .mx-option {
      background-color: transparent;
      padding-left: 0;
    }

    .mx-contact-list-icon {
      font-size: 22px;
      margin-right: 6px;
      display: flex;
      align-items: center;
    }
  }

  mark {
    color: black;
    background: none;
  }

  // These styles exist to enable copy-pasting into an otherwise empty input
  // See: https://github.com/reverie/mixily/pull/58
  // And: https://github.com/reverie/mixily/pull/690
  &.empty {
    div {
      width: 100% !important;
      padding: 0;
      margin: 0;
    }
    #react-select-2-input {
      position: relative !important;
      padding-left: 10px !important;
    }
  }
}
