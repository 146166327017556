@import './src/styles/lib';

.content {
  min-height: 330px;
}

.description {
  margin-bottom: spacing(2);
}

.form {
  margin-bottom: spacing(3);
}

.label {
  @include label-overline;
  margin-bottom: spacing(1);
}
