@import './src/styles/lib';

.Search {
  position: relative;

  &.open {
    .SearchInput {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      border-color: $gray-e;
    }
  }
}

.SearchInput {
}

.SearchResults {
  background: $white;
  position: absolute;
  width: 100%;
  z-index: 1;

  max-height: 200px;
  overflow-y: auto;

  border: 1px solid $gray-e;
  border-top: 0;
  margin: 0;
  padding: 0;
}

.Item {
  padding: 8px 20px;
  display: flex;
  align-items: center;

  cursor: pointer;

  &.highlight {
    background: $gray-f;
  }

  .title {
    font-size: 14px;
    color: $black;
  }
}
