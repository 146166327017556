@import './src/styles/lib';

// https://github.com/reverie/mixily/pull/814
// overflow should be visible so the calendar picker
// doesn't get under the modal title
.ScheduleTimeDialog--picker {
  .MuiDialog-paper {
    @media (min-width: #{$screen-mdek-min-width}) and (min-height: #{$screen-mdek-min-height}) {
      overflow-y: visible !important;
    }
  }

  .MuiDialogContent-root {
    overflow-y: visible !important;
  }
}
