@import './src/styles/lib';

.NameIcon {
  border: 1px solid rgba($black, .2);
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: rgba($black, 0.05);
  vertical-align: middle;
  margin-right: 4px;
  text-transform: capitalize;
  position: relative;
  top: -8px;

  &:hover {
    border: 1px solid rgba($black, .5);
    background: rgba($black, 0.1);
  }
}
