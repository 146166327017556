@import './src/styles/lib';

.alignLastTabRight {
  flex-wrap: wrap;

  > * {
    &:last-child {
      margin-left: auto;
      margin-right: 0;
    }
  }
}

.Tab {
  display: flex;
  flex-direction: column;

  height: 360px;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  &.center {
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  &.dragging {
    border: 2px dashed $gray-e;
  }

  .children {
    > * {
      margin-bottom: $smallSpacing;
    }
  }
}

.info {
  display: block;
  text-align: center;

  > * {
    margin-bottom: $tinySpacing;
  }
}

.input {
  min-height: 44px;
}

$ig-num-cols: 5;
// NOTE(nick): golden ratio
$ig-item-width: 120px;
$ig-item-height: 74px;
$ig-item-spacing: $tinySpacing;
$ig-grid-width: $ig-num-cols * ($ig-item-width + $ig-item-spacing);

.ImageGrid {
  display: flex;
  align-items: center;
  flex-direction: column;

  max-width: $ig-grid-width;
  margin-bottom: $smallSpacing;

  &.size-lg {
    .Image > .img {
      // NOTE(nick): 4 cols, golden ratio
      width: 152px;
      height: 94px;
    }
  }

  > .title {
    @include font-sm;
    width: 100%;
    text-align: left;
    margin-bottom: $tinySpacing;
  }

  > .inner {
    width: 100%;
    line-height: 0;

    display: flex;
    flex-wrap: wrap;

    margin-left: -$ig-item-spacing;

    > * {
      display: inline-block;
      margin: $ig-item-spacing / 2;
    }
  }
}

.Image {
  > .img {
    cursor: pointer;

    width: $ig-item-width;
    height: $ig-item-height;

    background-color: $gray-e;
    background-size: cover;
    background-position: center;

    border-radius: 4px;

    &:hover {
      opacity: 0.7;
    }
  }

  > .attrib {
    line-height: 1.2;
    font-size: 12px;
    margin: 4px 0;

    max-width: $ig-item-width;

    > a {
      color: $gray-8;

      &:hover {
        color: $orange;
      }
    }
  }
}
