@import './src/styles/lib';

.timeInput {
  @include form-text-input;
  width: 100%;
  @include sm {
    width: 160px !important;
  }
}

.disabled {
  opacity: 0.5;
}
