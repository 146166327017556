@import './src/styles/lib';

.PollView {
  display: flex;
  flex-direction: column;

  .option {
    margin-bottom: $tinySpacing;

    .header {
      display: flex;
      flex-direction: row;
      align-items: center;

      .label {
        color: $gray-8;
        font-size: 14px;
        flex-grow: 1;
      }

      .votes {
          min-width: 50px;
          text-align: right;
      }
    }

    .track {
      background: $gray-e;
      height: 20px;
      width: 100%;
      border-radius: 4px;
      overflow: hidden;

      .meter {
        background: $orange-6;
        border-radius: 4px;
        height: 20px;
      }
    }
  }

  .actionButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 5px;

    .separator {
      @include separator();
    }
  }
}
