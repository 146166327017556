@import './src/styles/lib';

.label {
  @include font-xl;
  margin-bottom: spacing(1);

  @include md {
    margin-bottom: 0;
  }
}

.sublabel {
  @include font-base;
  margin-bottom: spacing(2);
}

.votes {
  display: inline-block;
  margin-left: spacing(1);
}

.footer {
  @include flex-row;
  margin-top: 32px;
  justify-content: flex-end;
}

.saveButton {
  min-width: 84px !important;
}
