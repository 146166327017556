@import './src/styles/lib';

.PollEditOption {
  margin-bottom: spacing(1);
  &:not(:first-of-type) {
    margin-top: $spacing;
  }

  @include sm {
    margin-bottom: 0;
  }

  .top {
    display: flex;
    flex-direction: row;
  }

  .bottom {
    margin-top: 8px;
  }

  .votes,
  .smallButton {
    @include font-sm;
    margin-left: spacing(1);
    opacity: 0.5;
  }

  .votes {
    min-width: 50px;
    padding-right: spacing(1);
  }

  .smallButton {
    transition: opacity 0.3s;
    cursor: pointer;
    height: $smallInputHeight;

    &.icon {
      @include font-xl;
    }

    &:hover {
      opacity: 1;
    }
  }
}
