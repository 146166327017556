@import './src/styles/lib';

.SelectableList {
  &Item {
    cursor: pointer;
    padding: 16px !important;
    border-top: 1px solid $gray-d !important;
    border-left: 1px solid $gray-d;
    border-right: 1px solid $gray-d;
    &:last-child {
      border-bottom: 1px solid $gray-d;
    }
    &Content {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
    }
  }
}
.Checkbox {
  label:before {
    border-radius: 0 !important;
    border: 1px solid $black !important;
  }
}
.Empty {
  color: #aaa;
  text-align: center;
}
.RealContent {
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  color: $black;

  &Timestamp {
    font-size: 16px;
  }
  &Text {
    margin-top: $tinySpacing;
    display: block;
    font-size: 14px;
    line-height: 17px;
    max-height: 20px;
    overflow: hidden;
    padding-bottom: 2px;
  }
}
.ReminderContent {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.links {
  display: flex;
  margin-left: $smallSpacing;

  @include sm {
    margin-left: 0;
  }

  > * {
    padding-right: $tinySpacing;
  }
}

.tags {
  color: $orange;
  display: flex;
  align-items: center;
  margin-right: spacing(0.5);

  .tag {
    display: flex;
  }
}
