@import './src/styles/lib';

.EventListItem {
  display: flex;
  flex-direction: column;

  overflow: hidden;

  @include font-base;
  color: $black;
  border: 1px solid $gray-e;

  transition: padding 0.2s, border-color 0.2s;

  @include md {
    flex-direction: row-reverse;
  }

  &:hover {
    border-color: $orange;
    text-decoration: none;
  }

  .header {
    display: flex;
    flex-direction: column;

    @include md {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 0;
    }
  }

  @include sm {
    @include font-lg;

    > .details {
      @include child-space-y(16px);
      padding: $spacing;
    }
  }
}

.image {
  width: auto;
  height: 160px;

  background-size: cover;
  background-color: $gray-e;
  background-position: center;

  display: flex;
  justify-content: center;
  align-items: center;

  @include md {
    width: 330px;
    height: auto;
  }
}

.details {
  @include child-space-y(12px);
  padding: $smallSpacing;
  flex: 1;
  min-width: 0;
  position: relative;
}
