@import './src/styles/lib';

.button {
  width: spacing(7);
  height: spacing(7);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0;

  > span {
    margin: 3px 0 0 !important;
  }
}

.menu {
  border-top: 1px solid $black-fade !important;
  background-color: darken($black, 8);
}

.paper {
  border-top: 1px solid $black-fade !important;
  border-radius: 0 !important;
}

.menuItem {
  color: $white !important;

  &:hover {
    background-color: rgba(0, 0, 0, 0.9) !important;
  }
}

