@import './src/styles/lib';

.MessageWidget {
  :global {
    .ql-container {
      border: 1px solid rgba(0,0,0,0.25) !important;
      border-radius: 0 !important;
    }
  }
}
