$black: #393939;
$black-fade: transparentize(#393939, 0.5);

$gray-6: #636363;
$gray-8: #838383;
$gray-a: #AAA;
$gray-d: #d0d0d0;
$gray-e: #eaeaea;
$gray-f: #f2f2f2;
$gray-ff: #fafafa;

// Tailwind colors
$gray-900: #4a5568;

$white: #fff;
$orange: #ff8653;
$orange-6: #ffaa86;
$orange-c: #ffdbcc;
$orange-7: #fff9f7;
$beige: #FDFBF8;

$red: #fb4949;
$successMessage: #d8eacc;
$errorMessage: #fbcfbd;
$warningMessage: #fbeeca;

$messageNegativeColor: #9f3a38;
$messageNegativeBg: #fff6f6;
$messageNegativeBorder: #e0b4b4;
$messagePositiveColor: #2c662d;
$messagePositiveBg: #fcfff5;
$messagePositiveBorder: #a3c293;

$formErrorRed: #db2828;

$backgroundColor: $beige;
$textColor: $black;
$primaryColor: $orange;
$linkColor: $orange;
$buttonPrimaryColor: $black;
$buttonSecondaryColor: $white;
$tooltipBgColor: $gray-f;
