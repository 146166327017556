@import './src/styles/lib';

.GoogleButton {
    display: flex;
    align-items: center;
    width: 100%;
    height: 56px;
    box-shadow: none;
    border: 1px solid $black;
    background: $black;
    padding: 0 16px;
    font-family: $fontStack;
    font-weight: normal;
    text-align: left;
    @include font-lg;
    cursor: pointer;

    &:hover {
      opacity: .8;
    }
}

.GoogleButtonLogo, .GoogleButtonLogo img {
  height: 24px;
  width: 24px;
  min-width: 24px;

  align-self: center;
  vertical-align: unset;
}

.GoogleButtonLabel {
  padding-left: 16px;
  color: $white;
}
