@import './src/styles/lib';

.Message {
  padding: 15px 20px;
  border-radius: 4px;
  font-size: 14px;

  &.positive {
    color: $messagePositiveColor;
    background-color: $messagePositiveBg;
    border: 1px solid $messagePositiveBorder;
  }

  &.negative {
    color: $messageNegativeColor;
    background-color: $messageNegativeBg;
    border: 1px solid $messageNegativeBorder;
  }

  &:not(.compact) {
    width: 100%;
  }
}
