@import './src/styles/lib';

.FieldError {
  padding: 4px 12px;
  border-radius: 4px;

  display: inline-block;
  position: relative;

  color: $formErrorRed;
  border: 1px solid $formErrorRed;
  background: $white;

  font-size: $fontSizeSm;
  font-weight: 700;

  margin-top: 8px;

  &:before {
    content: ' ';
    display: inline-block;

    position: absolute;
    top: 0;
    left: 50%;

    margin-top: -5px;
    margin-left: -4px;

    width: 8px;
    height: 8px;

    border-top: 1px solid $formErrorRed;
    border-left: 1px solid $formErrorRed;
    background: $white;
    transform: rotate(45deg);
  }
}
