@import './src/styles/lib';

.Button {
  @include font-lg;

  height: $inputHeight;
  // NOTE(nick): to center the text vertically
  line-height: $inputHeight * 0.5 + 4;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;

  .icon {
    margin-right: 5px;
  }

  &.thin {
    padding: 12px;
  }

  border: 0;
  outline: none;

  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;

  white-space: nowrap;

  &:hover {
    text-decoration: none;
  }

  &.custom {
    // no defined style for custom
    cursor: pointer;
    &:hover, &:focus {
      background-color: darken(#ef8f71, 8);
    }

  }

  &.primary {
    color: $white;
    background-color: $buttonPrimaryColor;

    &:hover, &:focus {
      background-color: darken($black, 8);
    }
  }

  &.secondary {
    background-color: $buttonSecondaryColor;
    color: $black;
    border: 1px solid $black;

    &.selected {
      background-color: $black;
      color: $buttonSecondaryColor;
    }
  }

  &.red {
    background-color: $buttonSecondaryColor;
    color: $red;
    border: 1px solid $red;
  }

  &.small {
    min-width: max-content;
    line-height: 0;
    height: 36px;

    @include lg {
      height: $smallInputHeight;
    }
  }

  &:disabled {
    background-color: $gray-e;
    color: $gray-6;
    opacity: 0.25;
    cursor: default;
    &:hover {
      background-color: $gray-e;
    }
  }
}
