@import './src/styles/lib';

.button {
  cursor: pointer;
  width: 100%;
  background: #ffffff;
  appearance: none;
  border-radius: $radius;
  border: 1px solid $gray-e;
  padding: 13px; // 13px padding allows the button to be its designed height of 56px
  color: $orange;


  display: inline-flex;
  justify-content: center;
  align-items: center;

  transition: background 0.2s, color 0.2s;

  &:hover {
    background: #FFFAF8;
  }

  &[disabled] {
    color: #d0d0d0;
  }

  .text {
    @include font-lg;
    font-weight: 600;
    margin-left: $tinySpacing + 2px;
  }
}
