// Basic styles copied from somewhere -- need to be fixed to match site styles (TODO)
//
// Some styles, e.g. `p` padding, are chosen to match the content's
// appearance in the Prisma editor.

@import './src/styles/lib';

.RichText {
  h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
    margin-top: 32px;;
    line-height: 1.25em;
  }

  h1 {
    font-size: $fontSize3xl;
    margin-bottom: 4px;
    // color: $gray-6;
  }

  p {
    display: block;
  }
  p:not(:first-of-type) {
    padding-top: 16px;;
  }

  ul {
    list-style-type: disc;
    padding: 1em 0;
    margin-left: 1.5em;
  }

  ol {
    padding: 1em 0;
    list-style-type: decimal;
    margin-left: 1.5em;
  }
  li:not(:first-of-type) {
    margin-top: 12px;;
  }

}
