@import './src/styles/lib';

$fader-height: 56px;

.GuestListContainer {
  position: relative;
  overflow: hidden;
  border-top: 0;
}

.GuestList {
  height: 35vh;
  overflow-y: auto;
  padding-bottom: $fader-height;
}

.Fader {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: $fader-height;
  background: linear-gradient(180deg, rgba(255,255,251,0) 0%, rgba(255,255,254,0.4) 22%, rgba(255,255,255,1) 100%);
  pointer-events: none;
}

.NullState {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Modal {
  overflow: hidden;
}

.Popup {
  top: 25% !important;
  left: unset !important;
  right: 10% !important;
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: -(spacing(1));
  margin-bottom: spacing(2);

  .button {
    padding-left: spacing(2) !important;
    padding-right: spacing(2) !important;
    line-height: 20px;

    .label {
      opacity: 0.5;
      transition: opacity 0.3s;
    }

    &:hover {
      background-color: transparent;

      .label {
        opacity: 0.75;
      }
    }

    &:not(:last-child) {
      box-shadow: inset -1px 0 0 rgba(0,0,0,0.1);
    }

    &.active {
      .label {
        opacity: 1;
      }
    }
  }
}
