@import './src/styles/lib';

@mixin makeEventReadView($lg-min, $isNotDialog) {
  display: flex;
  flex-direction: column;
  justify-content: center;  //?

  margin-top: ($spacing - 4px) / 2;

  @include md {
    margin-top: $spacing/2;
  }
  // Prevent long lines from breaking the event page layout, across
  // all user-provided values (title, location, description...)
  word-break: break-word;

  $right-col-width: 420px;
  $col-spacing: $spacing;

  @media (min-width: #{$lg-min}) {
    display: block;

    .RightColumn {
      width: $right-col-width;
      float: right;
      clear: right;
    }

    .LeftColumn {
      width: $screen-lg-min - $right-col-width - $col-spacing - 2*$containerPadding;
    }
  }
  @if $isNotDialog {
    @include xl {
      .LeftColumn {
        width: $screen-xl-min - $right-col-width - $col-spacing - 2*$containerPadding;
      }
    }
  }
}

.EventReadView {
  @include makeEventReadView($screen-lg-min, true);
}

.EventReadDialogView {
  @include makeEventReadView($screen-mlg-min, false);
}

.DemoBlock {
  order: 0;
}

.EditEventButtonSection {
  order: 1;
}

.ViewAsGuestButtonSection {
  order: 2;
}

.EventBlock {
  order: 3;
}

.ActionSection {
  order: 4;
}

.CommentBlock {
  order: 5;
}


.EditEventButtonSection,
.ViewAsGuestButtonSection {
  margin-bottom: $spacing - 4px;

  @include md {
    margin-bottom: $spacing;
  }
}

.EventBlock, .ActionSectionBlock, .CommentBlock {
  border-radius: $radius;
  background-color: white;
  height: fit-content;
}

.ActionSectionBlock {
  margin-bottom: $spacing - 4px;

  @include md {
    margin-bottom: $spacing;
  }
}

.PrivacyBlock {
  margin-bottom: $spacing - 4px;

  @include lg {
    margin-bottom: 0;
  }
}

.LeftColumn {
  margin-bottom: $spacing - 4px;

  @include md {
    margin-bottom: $spacing;
  }

  @include lg {
    margin-right: $spacing / 2;
    width: 100%;
  }
}

.ActionSectionBlock {
  border: 1px solid $gray-e;
}

.HeaderImage {
  width: 100%;
  border: none;
  border-radius: $radius $radius 0 0;
  margin-bottom: -6px; // fixes spacing problem
  min-height: 126px !important;

  @include md {
    min-height: 201px !important;
  }

  @include xl {
    min-height: 275px !important;
  }
}

.MainInfo {
  border-radius: 0 0 $radius $radius;
  border: 1px solid $gray-e;
  border-top: none;
  word-break: break-word;
}

.TopBorder {
  border-radius: $radius;
  border: 1px solid $gray-e;
}

.ActionSectionBlock {
  padding: $spacing;
  @include sm {
    padding: $semiLargeSpacing;
  }
}

.MainInfo {
  padding: $spacing;
  @include sm {
    padding: $semiLargeSpacing;
  }
  @include xl {
    padding: $semiLargeSpacing;
  }
}

.ActionSectionBlockNoPadding {
  // Needed to achieve divider that is the full length of the card of the
  // i.e., the Guest List component. The padding must be edited, section by section,
  // in the stylesheet components that has a divider.
  padding: 0;
}

.Description {
  margin-top: 32px;
  max-width: 100%;
  img {
    max-width: 100%;
  }
}

.CommentBlock {
  border-radius: $radius;
  border: 1px solid $gray-e;

  padding: $spacing;
  @include sm {
    padding: $semiLargeSpacing;
  }
}
