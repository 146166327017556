@import './src/styles/lib';

.ticketsWidget {
  @include font-lg;
  color: $gray-6;

  margin-left: $smallSpacing;

  @include sm {
    margin-left: 0;
  }

  &.disabled {
    opacity: 0.5;
  }
}

.button {
  cursor: pointer;
  font-weight: 500;
  color: $orange;
  border-bottom: 1px dotted $orange;
}
