@import '../config/index';

@mixin event-page-section-heading {
  border-bottom: 1px solid #eee;
  display: inline-block;
  margin: 0;
  margin-bottom: 0.25rem;

  // Size-dependent
  min-width: 100%;
  @include md {
    min-width: 80%;
  }
}

@mixin input-container {
  background-color: $white;
  display: flex;
  flex-direction: column;
  padding: spacing(3);
  border-top: 1px solid $gray-d;
  border-bottom: 1px solid $gray-d;

  @include sm {
    border-radius: $radius;
    border: 1px solid $gray-d;
  }
}
.input-container {
  @include input-container;
}

@mixin separator($size: 4px) {
  background-color: $gray-8;
  border-radius: $size;
  width: $size;
  height: $size;
  margin: 0 $size * 4;
}

@mixin event-read-page-responsive-h4-heading {
  font-weight: 400;
  @include font-2xl;

  @include md {
    font-weight: 400;
    @include font-3xl;
  }
}
.event-read-page-responsive-h4-heading {
  @include event-read-page-responsive-h4-heading;
}

@mixin label-overline {
  @include font-xs;
  color: $gray-8;
  font-weight: 500;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.label-overline { @include label-overline; }

@mixin form-text-input {
  color: $black;
  outline: 0; // <-- doing this is bad for a11y reasons and might have SEO consequences
  height: 44px;
  padding: 6.5px 8px 6px 8px;
  border: 1px solid $gray-d;
  -webkit-appearance: none; // disable native mobile look (i.e. iOS Safari)

  @include md {
    padding: 13px 16px 12px 16px;
  }

  &:focus {
    border-color: $black;
  }
}


@mixin ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin border-top($color) {
  box-shadow: inset 0 1px 0 0 $color;
}

@mixin border-right($color) {
  box-shadow: inset -1px 0 0 $color;
}

@mixin border-bottom($color) {
  box-shadow: inset 0 -1px 0 0 $color;
}

@mixin border-left($color) {
  box-shadow: inset 1px 0 0 $color;
}

@mixin gray-border-top {
  @include border-top(rgba(0, 0, 0, 0.15));
}

@mixin gray-border-right {
  @include border-right(rgba(0, 0, 0, 0.15));
}

@mixin gray-border-bottom {
  @include border-bottom(rgba(0, 0, 0, 0.15));
}

@mixin gray-border-left {
  @include border-left(rgba(0, 0, 0, 0.15));
}

@mixin h2-responsive {
  @include font-4xl;
  @include sm {
    @include font-5xl;
  }
}

@mixin h3-responsive {
  @include font-3xl;
  @include sm {
    @include font-4xl;
  }
}

@mixin h4-responsive {
  @include font-2xl;
  @include sm {
    @include font-3xl;
  }
}
