@import './src/styles/lib';

$pillMarginRight: 6px;

.DayPill {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-right: $pillMarginRight;
  background-color: $gray-ff;
}

.TimePill {
  margin-right: $pillMarginRight;
  background-color: $gray-ff;
}

.timePickerWidgetWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 300px;

  .datePickerWidgetWrapper {
    width: 100%;
    margin-bottom: spacing(1);
    position: relative;
  }
}
