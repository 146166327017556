@import './src/styles/lib';

.MessageGuests {
  .Outer {
    @include md {
      display: grid;
      grid-template-columns: 65% 35%;
      grid-template-areas:
        "filter emailing"
        "compose emailing";
      grid-column-gap: $spacing;
    }
  }

  .Filter {
    grid-area: filter;
    margin-bottom: spacing(3);
  }

  .MessageGuestsCompose {
    grid-area: compose;
  }

  .Emailing {
    grid-area: emailing;
    margin-top: $smallSpacing;
    word-break: break-word;

    @include md {
      margin-top: 0;
    }
  }

  :global {
    .ql-container {
      min-height: 12em !important;
      max-height: 16em !important;
      overflow: scroll;
    }
  }
}

.guestList {
  max-height: 365px;
}
