@import './src/styles/lib';

.Divider {
  width: 100%;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  @include font-sm;
  color: $gray-8;
  font-family: $fontStack;

  // Using padding instead of margin because margin wasn't taking up document space
  &.tiny {
    padding-top: $tinySpacing;
    padding-bottom: $tinySpacing;
  }
  &.small {
    padding-top: $smallSpacing;
    padding-bottom: $smallSpacing;
  }
  &.medium {
    padding-top: $spacing;
    padding-bottom: $spacing;
  }
  &.large {
    padding-top: $largeSpacing;
    padding-bottom: $largeSpacing;
  }
  &.xlarge {
    padding-top: $xlargeSpacing;
    padding-bottom: $xlargeSpacing;
  }

  // responsive hr type line styles
  span {
    position: relative;
    display: inline-block;
  }

  span:before,
  span:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 9999px;
    height: 1px;
    background: $gray-e;
  }
  &.tiny { // Fixes some weirdness with padding causing the line to disappear
    span:before,
    span:after {
      height: 2px;
    }
  }
  span:before {
    right: 100%;
  }
  span:after {
    left: 100%;
  }
  .hasChildren:before {
    margin-right: 15px;
  }
  .hasChildren:after {
    margin-left: 15px;
  }
}
